import React, { Component } from "react";
import "./painelUsuario.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { HashLink as Link } from "react-router-hash-link";
import { urlAssinaturas } from "../../servicos/assinaturas-service";
import Button from "react-bootstrap/Button";
import clienteAlterar from "../../img/pessoaClienteAlterar.png";
import livroCrienteAlterar from "../../img/livroClienteResumo.png";
import clienteAlterarEndereco from "../../img/freteClienteResumo.png";
import cartao from "../../img/cartaoMasterCard.png";
import livroCapa from "../../img/livros/[sun-tzu]-a-arte-da-guerra.jpg";
import { Redirect } from "react-router";
import {
  idAtualLogado,
  entregarPessoaLogada,
  token,
  entregarIdLogado,
} from "../../servicos/pessoas-service";
import Swal from "sweetalert2";

class ResumoCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecionar: false,
      redirecionarAssinatura: false,
      pessoaAtual: entregarPessoaLogada,
      idAtual: entregarIdLogado,
      nome: "Uma pessoa qualquer",
      cpf: "123.***.***-23",
      telefone: "11-999**-**99",
      email: "umapessoaqualquer@gmail.com",
      assinatura: 12345,
      livroTitulo: "A Arte da Guerra",
      livroAutor: "Sun Tzu",
      livroImagem: "/static/media/[sun-tzu]-a-arte-da-guerra.d033d121.jpg",
      planoAderido: "1 Texto + 1 Livro / Anual",
      valorParcelado: "185,00",
      valorFrete: "15,90",
      planoValorComFrete: "115,70",
      valorTotal: "1.388,70",
      endereco: "Av. Paulista",
      cep: "05203-015",
      cidade: "São Paulo",
      qtdParcelas: 12,
      cartaoCredito: "****.****.****.1243",
      livroCapa: "[sun-tzu]-a-arte-da-guerra.d033d121.jpg",
    };
  }

  componentDidMount() {
    //console.log(this.state.idAtual)
    let livro = "/static/media/";
    fetch(urlAssinaturas + "ObterResumoAssinatura", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        assinanteId: this.state.idAtual,
        selecionarResumo: 1,
      }),
    })
      .then((resposta) => resposta.json())
      .then((resultadoJson) => {
        this.setState({ resultado: resultadoJson });

        if (!resultadoJson.hasOwnProperty("assinanteId")) {
          Swal.fire({
            icon: "question",
            title: "Você ainda não tem uma assinatura valida.",
            html: "Gostaria de criar uma assinatura?",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonColor: "#B29DCA",
          }).then((result) => {
            if (result.isConfirmed) {
              this.setState({ redirecionarAssinatura: true });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.setState({ redirecionar: true });
            }
          });
        } else {
          this.setState({
            nome: resultadoJson.assinanteNome,
            cpf: resultadoJson.assinanteCpf,
            telefone: resultadoJson.assinanteTelefone,
            email: resultadoJson.assinanteEmail,
            assinatura: resultadoJson.numeroAssinatura,
            livroTitulo: resultadoJson.ultimoLivroIndicadoTitulo,
            livroAutor: resultadoJson.ultimoLivroIndicadoAutor,
            livroImagem: livro + this.state.livroCapa,
            planoAderido: resultadoJson.planoAderidoDescricao,
            valorParcelado: parseFloat(resultadoJson.planoValorParcela).toFixed(
              2
            ),
            valorFrete: parseFloat(resultadoJson.planoFrete).toFixed(2),
            planoValorComFrete: parseFloat(resultadoJson.planoTotal).toFixed(2),
            valorTotal: (
              parseFloat(this.state.planoValorComFrete) * 12
            ).toFixed(2),
            endereco: resultadoJson.enderecoEntregaRuaNumeroComplemento,
            cep: resultadoJson.enderecoEntregaCep,
            cidade: resultadoJson.enderecoEntregaCidade,
            qtdParcelas: parseInt(resultadoJson.formaDePagamentoParcelamento),
            cartaoCredito: resultadoJson.formaDePagamentoCartao,
          });
        }
      })

      .catch((err) => {
        console.log("Erro ao se logar: " + err);
      });
  }

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/Cadastro" />;
    } else {
      return (
        <Container fluid className="inteiroMainCliente">
          <Container fluid className="mainCliente">
            <Container fluid className="tituloResumoCliente">
              <h2>Olá, {this.state.nome}!</h2>
              <p>Assinatura : #{this.state.assinatura}</p>
            </Container>
            <Container fluid className="ultimoLivroCliente">
              <p>O último livro recomendado para você foi:</p>
              <Container className="containerLivroAtual">
                <Container fluid className="livroAtual">
                  <h2>{this.state.livroTitulo}</h2>
                  <h3>{this.state.livroAutor}</h3>
                  <Link to="#">
                    <h5>Leia aqui seu texto biblioterapêutico deste livro</h5>
                  </Link>
                  <Link to="#">
                    <p>Acesse seu histórico</p>
                  </Link>
                </Container>
                <Container className="imagemLivro">
                  <Image src={this.state.livroImagem} className="livro" />
                </Container>
              </Container>
            </Container>
            <Container className="visaoGeral">
              <h2>Visão Geral</h2>
              <Container className="visaoGeralConteudo">
                <Container className="perfil">
                  <Container className="dadosPessoais">
                    <h2>Perfil:</h2>
                    <p>Nome: {this.state.nome}</p>
                    <p>CPF: {this.state.cpf}</p>
                    <p>Telefone: {this.state.telefone}</p>
                    <p>Email: {this.state.email}</p>
                  </Container>
                  <Container className="alterar">
                    <Image src={clienteAlterar} />
                    <Button className="botaoClienteGeralAlterar">
                      Alterar
                    </Button>
                  </Container>
                </Container>
                <Container className="plano">
                  <Container className="planoAderido">
                    <h2>Plano Aderido:</h2>
                    <p>{this.state.planoAderido}</p>
                    <h2 style={{ width: "40px" }}>Valor:</h2>
                    <p>Plano Parcelado: R$ {this.state.valorParcelado}</p>
                    <p>Frete: R$ {this.state.valorFrete}</p>
                    <p>
                      Parcelas com Frete: R$ {this.state.planoValorComFrete}
                    </p>
                    <p>VALOR TOTAL: R$ {this.state.valorTotal}</p>
                  </Container>
                  <Container className="imgPlanoAderido">
                    <Image src={livroCrienteAlterar} />
                  </Container>
                </Container>
                <Container className="enderecoEntrega">
                  <Container className="dadosPessoais">
                    <h2 style={{ width: "150px" }}>Endereço de Entrega:</h2>
                    <p>{this.state.endereco}</p>
                    <p>CEP {this.state.cep}</p>
                    <p>{this.state.cidade}</p>
                  </Container>
                  <Container className="alterar">
                    <Image src={clienteAlterarEndereco} />
                    <Button className="botaoClienteGeralAlterarEndereço">
                      Alterar
                    </Button>
                  </Container>
                </Container>
                <Container className="formaDePagamento">
                  <Container className="dadosPessoais">
                    <h2 style={{ width: "150px" }}>Forma de Pagamento:</h2>
                    <p>
                      Parcelamento:{" "}
                      {this.state.qtdParcelas > 1
                        ? `${this.state.qtdParcelas} Vezes`
                        : "A vista"}
                    </p>
                    <p>
                      {" "}
                      {this.state.cartaoCredito != ""
                        ? `Cartão de Crédito: ${this.state.cartaoCredito}`
                        : "Debito em conta"}
                    </p>
                  </Container>
                  <Container className="alterar">
                    <Image src={cartao} />
                    <Button className="botaoClienteGeralAlterarCartao">
                      Alterar
                    </Button>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      );
    }
  }
}

export default ResumoCliente;
